import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const fetchProject = project => useApi(http.get(`/projects/${project}`));

export const fetchProjectEnrollments = (project, student, group = null) => {
  return useApi(
    http.get(`/projects/${project}/enrollments`, {
      params: { student, group },
    }),
  );
};

export const submitProject = ({ project, stage, student, records }) => {
  return useApi(
    http.post(`/projects/${project}/stages/${stage}/submissions`, {
      student,
      records,
    }),
  );
};

export const publishSubmission = (
  project: string,
  enrollment: string,
  stage: string,
) => {
  return useApi(
    http.post(`/projects/${project}/enrollments/${enrollment}/publish`, {
      stage,
    }),
  );
};
