import { defineStore } from 'pinia';
import { isEmpty, get } from 'lodash';
import { IEnrollment, IUser } from '/@/types/interfaces';
import { fetchStudentEnrollments } from '/@/services/students';
import { fetchCourseModule } from '/@/services/groups';
import { collect } from 'collect.js';

declare function Upscope(
  command: 'updateConnection',
  data: {
    uniqueId: string | undefined;
    identities: string[];
  },
): void;

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    currentIndexImage: -1,
    user: {} as IUser,
    config: {} as any,
    currentEnrollment: {} as IEnrollment,
    enrollments: [] as IEnrollment[],
    groups: [] as any,
    isAuthenticated: false as boolean,
    courseModules: {},
    shouldMatch: null as null | string,
  }),
  getters: {
    courseLinks: state => {
      const disciplineMap = {};

      state.enrollments.forEach(entry => {
        entry.course.disciplines?.forEach(discipline => {
          if (!disciplineMap[discipline]) {
            disciplineMap[discipline] = [];
          }
          disciplineMap[discipline].push({
            to: {
              name: 'course-dashboard',
              params: { course: entry.course.id },
            },
            text: entry.course.caption,
          });
        });
      });

      return [
        ...Object.keys(disciplineMap).map(discipline => ({
          text: discipline,
          subLinks: disciplineMap[discipline],
        })),
      ];
    },
    registeredCourses: state => {
      return collect(state.enrollments)
        .map(enrollment => {
          return enrollment.course;
        })
        .unique('id')
        .toArray();
    },
    // isOnboarded: state => {
    //   return state.user.is_onboarded;
    // },
    currentUser: state => {
      return state.user;
    },
    userAvatar(state) {
      return state.user.avatar || '';
    },
  },
  actions: {
    setEnrollments(course = '') {
      if (isEmpty(this.user)) {
        return { onSuccess: () => {} };
      }
      this.enrollments = [];
      const { onSuccess, onError } = fetchStudentEnrollments(
        this.user.id,
        course,
      );
      onSuccess(response => {
        if (response.data?.length) {
          this.enrollments = response.data.filter(
            enrollment => enrollment.group,
          );
        }
      });
      onError(error => {
        console.log(error);
      });
      return { onSuccess };
    },
    setIndex(index: number) {
      this.currentIndexImage = index;
    },
    getUser() {
      return Promise.resolve(this.user);
    },
    setUser(data) {
      this.isAuthenticated = true;
      this.user = data;
      this.config = { currentYear: data.currentYear };

      // @ts-ignore
      window.$fvIdentity = {
        id: data.id,
        name: `${data.fullName}`,
        disableReplaysForUser: import.meta.env.MODE !== 'production',
        school: data.school?.name,
        roles: data.role.concat(import.meta.env.MODE),
        env: data.school?.name, //import.meta.env.MODE,
      };

      Upscope('updateConnection', {
        uniqueId: data.id,

        identities: [data.fullName, data.school?.name],
      });

      this.groups = data.groups.filter(group =>
        group.students.find(user => user.id === this.user.id),
      );
    },
    getCurrentEnrollment(course) {
      return (
        this.enrollments.find(enrollment => enrollment.course?.id === course) ||
        {}
      );
    },
    getEnrollmentSummary(course, lesson) {
      const enrollment = this.getCurrentEnrollment(course);
      const summary: any = get(enrollment, 'summary', []);
      return summary.find(s => s.lesson.id === lesson) || {};
    },
    getCurrentLesson(enrollment) {
      if (!enrollment?.summary?.length) {
        return '';
      }

      const currentLessonID = enrollment.current_lesson;
      const currentLesson = enrollment.summary.find(
        ({ lesson }) => lesson && lesson.id === currentLessonID,
      );
      if (!currentLesson || currentLesson.locked) {
        return (
          enrollment.summary.findLast(lesson => lesson && !lesson.locked)
            ?.lesson?.id || ''
        );
      }
      return currentLessonID;
    },
    onUserLogout() {
      this.user = {} as IUser;
      this.courseModules = {};
    },
    setCourseModules(group, course) {
      if (this.courseModules[course]) {
        return;
      }

      const { onSuccess } = fetchCourseModule({
        group,
        course,
      });
      onSuccess(({ data }) => {
        if (data.length) {
          this.courseModules[course] = data[0];
        }
      });
    },
    getCourseModule(course) {
      return this.courseModules[course];
    },
  },
});
