<script setup lang="ts">
import { computed, resolveComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useSubscription } from '@vue/apollo-composable';

import getNotification from '/@/graphql/notification.graphql';
import emitter from '@amit/composables/src/useBus';
import { useNotificationStore } from '/@/store/notifications';
import { useAuth } from '@amit/auth';
import { useAccountStore } from '/@/store/account';

const LoadingOverlay = resolveComponent('LoadingOverlay');
const NotificationToast = resolveComponent('NotificationToast');

const { authenticated, authenticating, onLogin } = useAuth();
const route = useRoute();
const notificationsStore = useNotificationStore();
const accountStore = useAccountStore();

onLogin(async userInfo => {
  accountStore.setUser(userInfo);
  notificationsStore.getMessages(accountStore.user.id);
});

const authReady = computed(() => {
  return (
    authenticated.value || (route.name === 'app-login' && !authenticating.value)
  );
});

const { onResult } = useSubscription(getNotification, () => ({}));
onResult(data => {
  emitter.emit('notification', data.data.notifications);
  notificationsStore.addNotification(data.data.notifications);
});
</script>

<template>
  <LoadingOverlay :active="!authReady" is-full-page :can-cancel="false" />
  <NotificationToast />
  <RouterView :key="$route.path" />
</template>
