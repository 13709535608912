import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const getSurveyEnrollments = (survey, user, group = null) => {
  return useApi(
    http.get(`/surveys/${survey}/enrollments`, { params: { user, group } }),
  );
};

export const getQuestionnaireSubmissions = (survey, questionnaire, student) => {
  return useApi(
    http.get(`/surveys/${survey}/submissions`, {
      params: { student, questionnaire },
    }),
  );
};

export const submitSurvey = ({
  survey,
  questionnaire = '',
  student,
  records,
}) => {
  return useApi(
    http.post(
      `/surveys/${survey}/questionnaires/${questionnaire}/submissions`,
      {
        student,
        records,
      },
    ),
  );
};
