import { createApp, defineAsyncComponent, h, provide } from 'vue';
import { createPinia } from 'pinia';
import { router } from '/@/router';

import { ApolloClients } from '@vue/apollo-composable';
import apolloClient from '@amit/composables/src/useSocket';

import appPlugins from '@amit/composables/plugins';
import { Origin, Scope } from '@amit/auth';

import App from './App.vue';
import NotificationToast from '/@/components/NotificationToast.vue';

import 'vue-loading-overlay/dist/css/index.css';
import './styles/base.css';

const store = createPinia();
const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    });
  },
  render: () => h(App),
});

app.use(appPlugins, {
  origin: Origin.Students,
  scope: [Scope.Student, Scope.Teacher],
  router,
  store,
});

app.component('NotificationToast', NotificationToast);
app.component(
  'LoadingOverlay',
  defineAsyncComponent(() => import('vue-loading-overlay')),
);

app.mount('#app');
