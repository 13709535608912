import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export default function saveAvatar(student, value) {
  return useApi(
    http.put(`/users/${student}/avatar`, {
      avatar: value,
    }),
  );
}

export const fetchStudentEnrollments = (student, course) => {
  const params: Record<string, string> = {
    ...(course && { course }),
  };
  return useApi(http.get(`students/${student}/enrollments`, { params }));
};

export const fetchSubmissions = ({
  student = '',
  exercise = '',
  group = '',
  blockPopulate = false,
}) => {
  const params: Record<string, string> = {
    ...(student && { participants: student }),
    ...(exercise && { exercise }),
    ...(group && { group }),
    ...(blockPopulate && { populate: 'records.question' }),
  };

  return useApi(http.get('/submissions', { params }));
};

export const updatePersona = (enrollment, personaType, persona) => {
  return useApi(
    http.put(`/enrollments/${enrollment}/persona`, {
      type: personaType,
      persona,
    }),
  );
};

export const submitAnswer = ({ enrollment, block, answer }) => {
  return useApi(
    http.post(`/enrollments/${enrollment}/submissions`, {
      block,
      answer,
    }),
  );
};
