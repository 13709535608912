import _, { isEmpty } from 'lodash';

const translateLevel = level => {
  switch (level) {
    case 'basic':
      return 'בסיסית';
    case 'medium':
      return 'בינונית';
    case 'advanced':
      return 'מתקדם';
    default:
      return 'בסיסי';
  }
};

export const formatLearningHistoryDetails = (enrollment: any) => {
  if (isEmpty(enrollment)) return [];
  console.log('enrollment', enrollment);
  const lessons = _.chain(_.get(enrollment, 'summary', [])) // Start Lodash chain with default empty array
    .filter(summary => _.get(summary, 'lesson.published', false)) // Filter by `published === true`
    .sortBy(summary => _.get(summary, 'lesson.lesson', '')) // Sort by 'lesson.lesson.lesson'
    .map(summary => ({
      id: _.get(summary, 'lesson.id', null),
      unit: _.get(summary, 'lesson.unit', null),
      title: _.get(summary, 'lesson.title', 'Untitled'),
      level: translateLevel(_.get(summary, 'level', '')),
      progress: _.get(summary, 'progress', 0),
      coins: _.get(summary, 'coins', 0),
      score: _.get(summary, 'published_score', null),
      sections: _.get(summary, 'sections', []),
      locked: _.get(summary, 'locked', false),
      unlock_at: _.get(summary, 'unlock_at', null),
    }))
    .value();

  return {
    enrollment_total_lessons: enrollment.total_lessons,
    enrollment_total_subjects: enrollment.total_subjects,
    enrollment_total_progress: enrollment.total_progress,
    enrollment_total_exercises: enrollment.total_exercises,
    course_total_lessons: enrollment.course.total_lessons,
    course_total_subjects: enrollment.course.total_subjects,
    course_total_exercises: enrollment.course.total_exercises,
    score: enrollment.published_score,
    lessons,
    units: enrollment.course.units.map(unit => ({
      ...unit,
      lessons: [...lessons.filter(lesson => lesson.unit === unit.id)],
    })),
  };
};
