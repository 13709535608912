<template>
  <ResubmitDialog v-model="dialogShown" />
  <AppHeader :dir="rtl ? 'rtl' : 'ltr'" :translate="rtl ? 'yes' : 'no'">
    <template #prefix>
      <CourseLink
        :course="currentCourse?.id"
        :caption="currentCourse?.caption"/>
    </template>
  </AppHeader>
  <TeamPanel v-if="showTeamPanel" :rtl="rtl" :team="team" />
  <PreviewLesson
    v-if="slideshow.id"
    :id="slideshow.id"
    :title="menuTitle"
    :translate="rtl ? 'yes' : 'no'"/>
</template>

<script setup lang="ts">
import { PreviewLesson, createPresentation } from '@nwire/amit-design-system';
import type { Presentation } from '@nwire/amit-design-system';
import {
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  computed,
  watch,
  Ref,
  nextTick,
} from 'vue';
import { useStorage } from '@vueuse/core';
import { get } from 'lodash';
import { fetchCourse } from '/@/services/courses';
import { fetchPages } from '/@/services/lessons';
import { setTrack } from '/@/services/enrollments';
import { updatePersona } from '/@/services/students';
import { useActivities } from '/@/composables/useActivities';
import { useState } from '/@/composables/useState';
import { IEnrollment } from '/@/types/interfaces';
import { useRoute, useRouter } from 'vue-router';
import { useAccountStore } from '/@/store/account';
import { useCourseStore } from '/@/store/course';
import AppHeader from '/@/layouts/AppHeader.vue';
import { useSubscription } from '@vue/apollo-composable';
// @ts-ignore
import getGroupSubmissionBroadcast from '/@/graphql/groupSubmission.graphql';
import { useStateMapper } from '/@/composables/useStateMapper';
import CourseLink from '/@/components/CourseLink.vue';
import ResubmitDialog from '/@/components/ResubmitDialog.vue';
import { useSubmissionsStore } from '/@/store/submissions';
import TeamPanel from '/@/components/TeamPanel.vue';

interface IOutline {
  id: string;
  title: string;
  selected: boolean;
  locked: boolean;
}

const courseStore = useCourseStore();
const accountStore = useAccountStore();
const submissionStore = useSubmissionsStore();

const {
  getQuestionSubmission,
  recordSubmission,
  syncEnrollmentPage,
  getSubmissions,
} = useActivities();
const { mapWordCloudSubmission, mapPollSubmission } = useStateMapper();
const route = useRoute();
const router = useRouter();
const slideshow: Presentation = ref({
  id: '',
  course: '',
  lesson: 0,
  cover: '',
  introduction: '',
  title: '',
  subject: '',
  description: '',
  welcome_message: '',
  final_message: '',
  unit: {},
  display: {
    rtl: true,
  },
  sections: [],
});

const currentCourse = ref();
const locale = ref();
const localState = useStorage(accountStore.user.id, {});
const user = ref(accountStore.user);
const initialLessonHistory = ref();
const dialogShown = ref(false);
const rtl = computed(() => !!slideshow.value?.display?.rtl);

const outline: Ref<IOutline[]> = ref([]);

const currentOutlineIndex = computed<number>(() => {
  let selected = outline.value.findIndex(item => item.selected);
  return selected >= 0 ? selected : 0;
});

const menuTitle = computed(() =>
  slideshow.value.lesson
    ? `שיעור ${currentOutlineIndex.value + 1} - ${slideshow.value.title}`
    : '',
);
const courseEnrollment = computed(
  () => accountStore.getCurrentEnrollment(route.params.course) as IEnrollment,
);
const enrollmentSummaryTracks = computed(
  () =>
    accountStore.getEnrollmentSummary(route.params.course, route.params.lesson)
      ?.tracks || [],
);
const personaState = computed(() => ({
  assistant: courseEnrollment.value?.assistant || '',
}));

const courseModule = computed(() => {
  return accountStore.getCourseModule(route.params.course);
});

const team = computed(() => {
  const teams = courseModule.value?.teams ?? [];
  return (
    teams.find(team => {
      return team.students.some(student => student.id === accountStore.user.id);
    })?.students ?? []
  );
});

const showTeamPanel = computed(() => {
  const lessonsSummary = courseModule.value?.summary ?? [];
  const lesson = lessonsSummary.find(
    ({ lesson }) => lesson === route.params.lesson,
  );

  return team.value.length && lesson?.teams_enabled;
});

// const exitPollSubmissions = ref();
const page: any = ref(route.query.page);
const history: any = ref([]);

const { getPageState, getDisabled, getQuestionSet, getRules } = useState({
  tracks: enrollmentSummaryTracks,
  personaState,
});

const { onResult: onGroupSubmissionResult } = useSubscription(
  getGroupSubmissionBroadcast,
  () => ({}),
);

const {
  onOutlineChange,
  onAction,
  onNext,
  onReady,
  onSlide,
  onSubmit,
  start,
  onEmit,
  onTrackChange,
  currentSlide,
} = createPresentation({
  slideshow,
  outline,
  tracks: enrollmentSummaryTracks,
  page,
  history,
  immediate: false,
  locale,
  user,
});

onGroupSubmissionResult(({ data }) => {
  const sunmissions = data.submissions;
  if (!sunmissions) {
    return;
  }
  const blockId = sunmissions.records[0].question;
  const currentBlock = currentSlide.value.blocks.find(
    block => block.id === blockId,
  );
  if (!currentBlock) {
    return;
  }
  if (currentBlock.name === 'word_cloud') {
    const wordCloudData = mapWordCloudSubmission(sunmissions, user.value.id);
    currentSlide.value.state[blockId] = wordCloudData.state;
    currentBlock.content['words'] = wordCloudData.words;
  }

  if (currentBlock.name === 'poll') {
    const pollData = mapPollSubmission(sunmissions, user.value.id);
    currentSlide.value.state[blockId] = pollData.state;
    currentBlock.content['answers'] = pollData.answers;
  }
});

onMounted(() => {
  const { onSuccess } = accountStore.setEnrollments(
    route.params.course as string,
  );
  onSuccess(() => {
    const summary = get(courseEnrollment.value, 'summary', []);
    const lessonSummary = summary.find(
      (item: any) => item.lesson.id === route.params.lesson,
    );
    if (lessonSummary.locked) {
      router.push({
        name: 'course-home',
        params: {
          course: route.params.course,
        },
      });
    }
    initialLessonHistory.value = get(lessonSummary, 'history', []);
    if (initialLessonHistory.value.length) {
      history.value = JSON.parse(JSON.stringify(initialLessonHistory.value));
      if (!page.value && lessonSummary.progress === 100) {
        page.value = initialLessonHistory.value[0];
      }
    }
  });

  courseStore.fetchExercises(route.params.course, route.params.lesson);
});

onUnmounted(() => {
  submissionStore.reset();
});

watchEffect(() => {
  if (!slideshow.value || !initialLessonHistory.value) {
    return;
  }

  slideshow.value.sections.forEach((section: any) => {
    section.pages.forEach(page => {
      const mediaBlocks = page.blocks.filter(block =>
        ['custom_video', 'persona_selection', 'audio_listener'].includes(
          block.name,
        ),
      );

      const isWatched = initialLessonHistory.value.includes(page.id);
      mediaBlocks.forEach(block => {
        block.watched = isWatched;
      });
    });
  });
});

watchEffect(() => {
  if (currentCourse.value && courseEnrollment.value) {
    start();
  }
});

watchEffect(() => {
  const group = courseEnrollment.value?.group;
  if (!group) {
    return;
  }

  accountStore.setCourseModules(group, route.params.course);
});

onOutlineChange(lesson =>
  router.push({
    name: 'skill-lesson',
    params: {
      lesson: lesson.id,
      course: route.params.course,
    },
  }),
);

watchEffect(() => {
  if (!currentCourse.value) return;
  outline.value = currentCourse.value.outline.map((item, index) => ({
    id: item.id,
    title: index + 1,
    selected: item.id === route.params.lesson,
    locked: courseEnrollment.value.summary?.find(
      lesson => lesson.lesson.lesson === item.lesson,
    )?.locked,
    current: courseEnrollment.value.current_lesson === item.id,
    finished: courseEnrollment.value.summary?.find(
      lesson => lesson.lesson.lesson === item.lesson,
    )?.awarded,
  }));
});

watch(
  () => route.params.lesson,
  () => window.location.reload(),
);

const fetchContent = (courseId, lessonId) => {
  const { onSuccess: onCourseFetched } = fetchCourse(courseId);
  onCourseFetched(({ data: course }) => {
    currentCourse.value = course;
    locale.value = course?.locale;
  });

  const { onSuccess: onLessonFetched } = fetchPages(courseId, lessonId);
  onLessonFetched(({ data: lesson }) => {
    slideshow.value = lesson;
  });
};

// watchEffect(() => {
//   if (slideshow.value?.exit_poll) {
//     const { onSuccess: onPollSubmissionsFetched } = getQuestionnaireSubmissions(
//       {
//         student: accountStore.user.id,
//         survey: slideshow.value?.exit_poll.survey,
//         questionnaire: slideshow.value?.exit_poll.questionnaire,
//       },
//     );
//     onPollSubmissionsFetched(({ data }) => {
//       exitPollSubmissions.value = data;
//     });
//   }
// });

watchEffect(() => fetchContent(route.params.course, route.params.lesson));

watch(
  () => slideshow.value,
  () => {
    if (!history.value.length && slideshow.value) {
      const firstPage = get(slideshow.value, 'sections[0]pages[0].id', null);
      if (firstPage) {
        history.value.push(firstPage);
      }
    }
  },
);

const setContentByQuestionSet = (blocks, pageDisabled) => {
  const questionSet = getQuestionSet(blocks);
  const calendarBlock = blocks.find(block => block.name === 'calendar');

  if (!questionSet.length || !calendarBlock.content?.events) {
    return;
  }
  questionSet.forEach(group => {
    const enabledBlock = blocks.find(
      block => group.questions.includes(block.id) && !pageDisabled[block.id],
    );
    if (group.behaviour === 'any' && enabledBlock) {
      for (const [index, event] of calendarBlock.content.events.entries()) {
        if (
          group.questions.includes(event.id) &&
          event.id !== enabledBlock.id
        ) {
          calendarBlock.content.events[index].clickable = false;
        }
      }
    }
  });
};

const setPersona = (contextBlocks, contextState) => {
  const personaBlock = contextBlocks.find(
    item => item.name === 'persona_selection',
  );

  if (!personaBlock) {
    return;
  }

  const enrollmentId = courseEnrollment.value.id;
  const personaId = contextState[personaBlock.id];
  const personaType = personaBlock.content.type;
  const isPersonaExist = courseEnrollment.value[personaType];

  if (!isPersonaExist) {
    updatePersona(enrollmentId, personaType, personaId);
    courseEnrollment.value[personaType] = personaId;
  }
};

const getQuestionSubmitted = async (question, digHistory = true) => {
  const submission = await getQuestionSubmission({
    exercise: question.exercise,
    question: question.id,
    submitByGroup: question.settings?.submitByGroup,
    digHistory,
  });
  return !!submission;
};

const updateLocalState = (pageId, event) => {
  if (!localState.value[pageId]) {
    localState.value[pageId] = {};
  }
  localState.value[pageId][event.block.id] = event.value;
};

const customConfirm = () => {
  return new Promise(resolve => {
    dialogShown.value = true;
    return nextTick(() => {
      const confirmButton = document.getElementsByClassName('confirmButton')[0];
      const cancelButton = document.getElementsByClassName('cancelButton')[0];
      confirmButton.onclick = () => {
        dialogShown.value = false;
        resolve(true);
      };

      cancelButton.onclick = () => {
        dialogShown.value = false;
        resolve(false);
      };
    });
  });
};

const getChangesRequested = async questions => {
  for (let question of questions) {
    const status = question.state?.submission?.status;
    const questionSubmitted = await getQuestionSubmitted(question, false);
    if (
      status === 'changes_requested' ||
      (status === 'pending_changes' && !questionSubmitted)
    ) {
      return true;
    }
  }
  return false;
};

// every use interaction with page (blocks)
// next is disabled by default if page settings does not say other
// here we can enable next
onAction(async ({ context, event, disableExercise = false }) => {
  if (!context) {
    return;
  }

  const pageId = context.getId();
  const contextState = context.state();
  const contextBlocks = context.flatBlocks();
  if (!Object.hasOwn(contextState, event.block.id)) {
    return;
  }

  if (event.value) {
    context.updateState(event.block.id, event.value);
    updateLocalState(pageId, event);
  }

  const { disabled, pageDisabled, hasQuestionSet } = getDisabled(
    contextBlocks,
    contextState,
  );

  let block = event.block;

  if (!hasQuestionSet || disableExercise) {
    context.setDisabled(disabled);
  }

  if (block?.state) {
    block.state.disabled = pageDisabled[block.id];
  }
});

onTrackChange(({ block, track }) => {
  const { onSuccess, onError } = setTrack(
    courseEnrollment.value.id,
    route.params.lesson,
    block.id,
    track,
  );
  onSuccess(() => {});
  onError(() => {});
});

onEmit(async ({ event, context }) => {
  if (
    [
      'custom_video:video-next-enabled',
      'persona_selection:video-next-enabled',
      'audio_listener:audio-next-enabled',
    ].includes(event.name)
  ) {
    const contextState = context.state();
    const contextBlocks = context.flatBlocks();
    let block = contextBlocks?.find(block => block.id === event.source);
    if (block) {
      block.watched = true;
    }
    const { disabled } = getDisabled(contextBlocks, contextState);

    context.setDisabled(disabled);
  }
});

const handleSlide = async context => {
  const pageId = context.getId();
  const contextState = JSON.parse(JSON.stringify(context.state()));
  const contextBlocks = context.flatBlocks();

  const questionBlocks = contextBlocks.filter(
    block => block.type === 'question',
  );

  for (let block of questionBlocks) {
    block.state = {
      submitting: false,
      submitted: false,
      editing: false,
      disabled: true,
      loading: true,
      submission: {},
      exercise: {},
      historyState: null,
    };
  }

  const { state } = await getPageState(
    contextBlocks,
    contextState,
    localState.value[pageId],
  );
  context.setState(state);

  for (let block of questionBlocks) {
    block.state.exercise = courseStore.getExercise(block.exercise);
    block.state.submission = submissionStore.getSubmission(block.exercise);
    block.state.submitted = await getQuestionSubmitted(block);
    block.state.loading = false;
  }

  const { disabled, pageDisabled } = getDisabled(contextBlocks, state);

  for (let block of contextBlocks) {
    if (block.state) {
      block.state.disabled = pageDisabled[block.id];
    }
  }

  context.setDisabled(disabled);
  setContentByQuestionSet(contextBlocks, pageDisabled);
  //FIXME - NO NEED FETCH EACH TIME
  const rules = await getRules(pageId);

  context.setRules(rules);

  let groupSubmissionBlocks = contextBlocks.filter(
    x => x.settings?.submitByGroup,
  );

  for (const groupSubmissionBlock of groupSubmissionBlocks) {
    const groupSubmissions = await getSubmissions({
      group: courseEnrollment.value.group,
      exercise: groupSubmissionBlock.exercise,
    });
    if (groupSubmissions?.data?.length) {
      if (groupSubmissionBlock.name === 'word_cloud') {
        const wordCloudData = mapWordCloudSubmission(
          groupSubmissions.data[0],
          user.value.id,
        );
        if (wordCloudData && wordCloudData.state.length > 0) {
          contextState[groupSubmissionBlock.id] = wordCloudData.state;
          context.setDisabled(
            contextState[groupSubmissionBlock.id]?.length <
              groupSubmissionBlock.content['minAnswers'],
          );
          groupSubmissionBlock.content['words'] = wordCloudData.words;
        }
      } else if (groupSubmissionBlock.name === 'poll') {
        const exitPollData = mapPollSubmission(
          groupSubmissions.data[0],
          user.value.id,
        );

        if (exitPollData && exitPollData.state.length > 0) {
          contextState[groupSubmissionBlock.id] = exitPollData.state;
          groupSubmissionBlock.content['answers'] = exitPollData.answers;
        }
      }
    }
    if (groupSubmissionBlock.name === 'collaborative_board') {
      groupSubmissionBlock.content['channelUrl'] = accountStore.courseModules[
        currentCourse.value.id
      ]?.settings?.collaborative_board.find(
        cb => cb.block === groupSubmissionBlock.id,
      ).channel_url;
      groupSubmissionBlock.content['currentUserAccount'] = {
        id: user.value.id,
        name: user.value.fullName,
      };
    }
  }
};

onReady(async ({ context }) => {
  await handleSlide(context);
});

onSlide(async ({ context }) => {
  //TODO - if onNext got triggered already - dont need to execute the code twice

  await handleSlide(context);
});

onSubmit(async ({ block, context }) => {
  const contextBlocks = context.flatBlocks();
  const contextState = context.state();

  const { submission } = await recordSubmission(
    courseEnrollment.value.id,
    contextState[block.id],
    block,
  );
  submissionStore.submissions[block.exercise] = submission;

  // eslint-disable-next-line no-param-reassign
  block.state = {
    ...block.state,
    submitting: false,
    submitted: true,
    submission,
  };

  const { disabled, pageDisabled } = getDisabled(contextBlocks, contextState);
  context.setDisabled(disabled);

  setContentByQuestionSet(contextBlocks, pageDisabled);
});

onNext(async context => {
  //TODO - DO WE NEED THIS CODE?
  if (context.get()) {
    context.next({});
    return;
  }

  const pageId = context.getId();
  const contextState = context.state();
  const contextBlocks = context.flatBlocks();
  const enrollmentId = courseEnrollment.value.id;

  if (!enrollmentId) {
    return;
  }
  const questionBlocks = contextBlocks
    .filter(
      block =>
        block.type === 'question' &&
        !['finished', 'awaiting_review'].includes(
          block.state?.submission?.status,
        ),
    )
    .filter(
      block =>
        !(
          block.settings?.submissionStyle === 'inline' ||
          block.settings?.display === 'modal'
        ),
    );

  const changesRequested = await getChangesRequested(questionBlocks);
  if (changesRequested) {
    if (!(await customConfirm())) {
      return;
    }
  }

  context.setLoading(true);

  setPersona(contextBlocks, contextState);

  const questionSet = getQuestionSet(contextBlocks);
  if (questionBlocks.length && !questionSet.length) {
    for (let [index, question] of questionBlocks.entries()) {
      const { feedback, exerciseFeedback, submission } = await recordSubmission(
        enrollmentId,
        contextState[question.id],
        question,
      );

      submissionStore.submissions[question.exercise] = submission;

      if (feedback && index === 0) {
        context.push(feedback);
      }
      if (exerciseFeedback) {
        context.push(exerciseFeedback);
      }
    }
  }

  const { instructions } = await syncEnrollmentPage(enrollmentId, pageId);

  delete localState.value[pageId];

  let nextSlide = context.sync({ instructions });
  if (!nextSlide) {
    context.setLoading(false);
    // if (slideshow.value.exit_poll && !exitPollSubmissions.value.length) {
    //   await router.push({
    //     name: 'onboarding',
    //     query: { finishedLesson: route.params.lesson },
    //     params: {
    //       course: route.params.course,
    //       survey: slideshow.value.exit_poll.survey,
    //       questionnaire: slideshow.value.exit_poll.questionnaire,
    //     },
    //   });
    //   return;
    // }
    await router.push({
      name: 'course-home',
      query: { finishedLesson: route.params.lesson },
      params: { course: route.params.course },
    });
    return;
  }
  if (!history.value.includes(nextSlide.id)) {
    const currentSlideIndex = history.value.indexOf(pageId);
    history.value.splice(currentSlideIndex + 1, 0, nextSlide.id);
  }

  const { state } = await getPageState(
    nextSlide.blocks,
    nextSlide.state,
    localState.value[nextSlide.id],
  );
  const { disabled, pageDisabled } = getDisabled(nextSlide.blocks, state);
  setContentByQuestionSet(nextSlide.blocks, pageDisabled);

  const rules = await getRules(nextSlide.id);

  context.setLoading(false);

  context.next({
    state,
    disabled,
    rules,
  });
});
</script>
