<template>
  <component
    :is="locked ? 'div' : 'router-link'"
    :to="{
      name: 'skill-lesson',
      params: {
        lesson: lesson.id,
      },
    }">
    <div
      class="flex items-center gap-3 p-3 rounded-md bg-white"
      :class="{ 'opacity-50': locked }">
      <div class="flex flex-grow-0">
        <LockKeyhole v-if="locked" :size="13" class="text-secondary-900" />
        <BookOpen v-else :size="13" class="text-secondary-900" />
      </div>
      <div class="flex w-40 sm:w-80 md:w-40 xl:w-80">
        <p
          v-tooltip.top="lesson.title"
          class="font-simplerRegular text-secondary-900 text-sm truncate">
          {{ lesson.title }}
        </p>
      </div>
      <div
        class="flex flex-[2] gap-2 font-simplerRegular text-secondary-900 text-xs min-w-[203px]">
        <ProgressInfo
          :progress="Math.round(lesson.progress)"
          :color="lessonStatus?.color"
          :label="lessonStatus?.label"/>
        <CustomScore v-if="lesson.score !== null" :score="lesson.score" />
      </div>
      <div
        v-if="!locked"
        class="flex flex-1 font-simplerRegular text-secondary-900 text-xs min-w-[60px]">
        <AmitCoin class="h-[14px] w-[14px] ml-1" />
        {{ `${lessonCoins} אמית קוינס` }}
        <!-- TODO: this is the coins info or lesson.coins ?? -->
      </div>
      <div
        class="flex flex-1 font-simplerRegular text-secondary-900 text-xs min-w-[60px]">
        <div v-if="team.length">
          <div
            class="flex gap-2 items-center bg-[#F4F7FB] p-1 rounded-sm cursor-pointer"
            @click="
              e => {
                e.stopPropagation();
                e.preventDefault();
                togglePanel(e);
              }
            ">
            <UsersIcon class="stroke-secondary-800 w-5" />
            <span class="text-secondary-800">למידה בקבוצות</span>
          </div>
          <OverlayPanel ref="teamPanel">
            <div class="p-3 w-[180px]">
              <div class="flex justify-between items-center mb-1.5">
                <div class="flex gap-1 items-center">
                  <span class="text-secondary-900 font-simplerBold">
                    החברים לקבוצה
                  </span>
                  <span class="text-[#9BAABF]">{{ ` (${team.length}) ` }}</span>
                </div>
                <CloseIcon
                  class="h-3.5 w-3.5 cursor-pointer stroke-secondary-900"
                  @click.stop="togglePanel"/>
              </div>
              <Divider />
              <div dir="ltr" class="h-[160px] mt-3 overflow-y-auto">
                <div dir="rtl" class="flex flex-col gap-2 px-3">
                  <div
                    v-for="student in team"
                    :key="student.id"
                    class="flex gap-2 items-center">
                    <img
                      :src="student.avatar || defaultAvatarImage"
                      :alt="student.fullName"
                      class="h-5 w-5 rounded-full"/>
                    <span>{{ student.fullName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </OverlayPanel>
        </div>
      </div>
      <div
        v-if="!locked"
        class="flex flex-[0.5] font-simplerRegular text-secondary-900 text-xs min-w-[60px] justify-end">
        <!-- TODO: -->

        <div
          class="flex p-1 bg-secondary-200 rounded-full w-5 h-5 items-center justify-cente cursor-pointer">
          <ArrowLeft :size="16" />
        </div>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import AmitCoin from './../assets/amitCoin.svg?component';
import { type PropType, computed, ref } from 'vue';
import { BookOpen, ArrowLeft, LockKeyhole } from 'lucide-vue-next';
import { ProgressInfo } from '@amit/components';
import { Users as UsersIcon, X as CloseIcon } from 'lucide-vue-next';
import { defaultAvatarImage } from '@/utils/helpers';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';
import { IStudent } from '/@/types/interfaces';
import CustomScore from './CustomScore.vue';

type Lesson = {
  id: number;
  title: string;
  locked: boolean;
  // status: string
  progress: number;
  // isCompleted: boolean
};

const props = defineProps({
  lesson: {
    type: Object as PropType<Lesson>,
    required: true,
  },
  lessonCoins: {
    type: Number,
    default: 0,
  },
  team: {
    type: Array,
    default: [] as PropType<IStudent[]>,
  },
});

const lessonStatuses = {
  LOCKED: {
    id: 'LOCKED',
    label: 'נעול',
    color: '#9BAABF',
    condition: (lesson: Lesson) => {
      return lesson?.locked;
    },
  },
  COMPLETED: {
    id: 'COMPLETED',
    label: 'הושלם',
    color: '#3F9F44',
    condition: (lesson: Lesson) => {
      return lesson?.progress === 100;
    },
  },
  NOT_STARTED: {
    id: 'NOT_STARTED',
    label: 'פתוח',
    color: '#0FA9FF',
    condition: (lesson: Lesson) => {
      const fistSectionProgress = lesson?.sections[0].progress;
      return fistSectionProgress < 100;
    },
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    label: 'בלמידה',
    color: '#0FA9FF',
    condition: (lesson: Lesson) => {
      const fistSectionProgress = lesson?.sections[0].progress;
      return (
        lesson?.progress > 0 &&
        lesson?.progress < 100 &&
        fistSectionProgress === 100
      );
    },
  },
};

const teamPanel = ref();
const lessonStatus = computed(() =>
  Object.values(lessonStatuses).find(({ condition }) =>
    condition(props.lesson),
  ),
);

const locked = computed(() => lessonStatus.value?.id === 'LOCKED');

const togglePanel = event => {
  teamPanel.value.toggle(event);
};
</script>
